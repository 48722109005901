export var Region;
(function (Region) {
    Region["De"] = "DE";
    Region["At"] = "AT";
    Region["Ch"] = "CH";
    Region["Fr"] = "FR";
    Region["It"] = "IT";
    Region["Be"] = "BE";
    Region["Nl"] = "NL";
})(Region || (Region = {}));
