export default {
    text: {
        default: '#db3532',
        hover: '#db3532',
        active: '#db3532',
        strong: '#640e07',
    },
    background: {
        default: '#e65b51',
        weak: '#fbebea',
        hover: '#db3532',
        active: '#b12923',
    },
    border: {
        default: '#e65b51',
        hover: '#e65b51',
        active: '#e65b51',
    },
    icon: {
        default: '#e65b51',
        hover: '#e65b51',
        active: '#e65b51',
        strong: '#b12923',
    },
};
