export default {
    text: {
        default: '#257e9e',
        hover: '#257e9e',
        active: '#257e9e',
        focus: '#257e9e',
        strong: '#033352',
    },
    background: {
        default: '#2b94b5',
        weak: '#e3f1f4',
        hover: '#257e9e',
        active: '#1c6484',
    },
    border: {
        default: '#2b94b5',
        hover: '#2b94b5',
        active: '#2b94b5',
        focus: '#2b94b5',
    },
    icon: {
        default: '#2b94b5',
        hover: '#2b94b5',
        active: '#2b94b5',
        strong: '#1c6484',
    },
};
