export default {
    text: {
        default: '#5b5e61',
        hover: '#47494c',
        active: '#2f3133',
        inactive: '#b9bcbe',
        inverted: '#ffffff',
        strong: '#2f3133',
    },
    background: {
        default: '#ffffff',
        inverted: '#b9bcbe',
        weak: '#eeeeef',
        weaker: '#f7f7f7',
        hover: '#eeeeef',
        active: '#d9dadb',
        strong: '#2f3133',
        inactive: {
            default: '#ffffff',
            inverted: '#b9bcbe',
        },
    },
    border: {
        default: '#717678',
        hover: '#5b5e61',
        active: '#47494c',
        inactive: '#b9bcbe',
        inverted: '#ffffff',
        weak: '#d9dadb',
        strong: '#2f3133',
    },
    icon: {
        default: '#717678',
        strong: '#2f3133',
        hover: '#5b5e61',
        active: '#47494c',
        inactive: '#b9bcbe',
        inverted: '#ffffff',
    },
};
