var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useContext } from 'react';
import name from 'react-display-name';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { Locale } from '../enums/Locale';
import { Region } from '../enums/Region';
import { BrandTheme } from '../theme/theme';
import localeOptions from './locale-options.json';
var LOCALES = [
    'de_AT',
    'de_CH',
    'de_DE',
    'fr_BE',
    'fr_CH',
    'fr_FR',
    'it_IT',
    'nl_BE',
    'nl_NL',
];
var MULTILINGUAL_REGIONS = [Region.Ch, Region.Be];
var options = localeOptions;
export var LocaleContext = React.createContext(process.env.NODE_ENV === 'test'
    ? {
        t: function (_) { return "{{".concat(_, "}}"); },
        translate: function (_) { return "{{".concat(_, "}}"); },
        r: function (_) { return _; },
        fn: function (_) { return _; },
        formatNumber: function (_) { return _; },
        fc: function (_) { return _; },
        formatCurrency: function (_) { return _; },
        replace: (function () {
            var replace = function (_) {
                if (_ === void 0) { _ = ''; }
                return _;
            };
            replace.t = function (_) { return "{{".concat(_, "}}"); };
            return replace;
        })(),
        locale: 'de_DE_h24',
        locales: {},
        language: 'de',
        region: Region.De,
        theme: BrandTheme.h24,
        getLanguages: function () { return [
            {
                isCurrent: true,
                pathPrefix: '',
                language: 'de',
                region: Region.De,
            },
        ]; },
        l: function () { return [
            {
                isCurrent: true,
                pathPrefix: '',
                language: 'de',
                region: Region.De,
            },
        ]; },
        prefix: (function () {
            var prefix = function (_) {
                if (_ === void 0) { _ = ''; }
                return _;
            };
            prefix.t = function (_) { return "{{".concat(_, "}}"); };
            return prefix;
        })(),
        p: (function () {
            var prefix = function (_) {
                if (_ === void 0) { _ = ''; }
                return _;
            };
            prefix.t = function (_) { return "{{".concat(_, "}}"); };
            return prefix;
        })(),
    }
    : null);
var LocaleProvider = /** @class */ (function (_super) {
    __extends(LocaleProvider, _super);
    function LocaleProvider(props) {
        var _a;
        var _this = _super.call(this, props) || this;
        if (!Object.values(Locale).includes(props.locale)) {
            throw new Error("Locale ".concat(props.locale, " is not valid."));
        }
        else {
            var localeConfig = _this.splitLocale(props.locale);
            var prefix = _this.prefix.bind(_this);
            var replace = _this.replace.bind(_this);
            var formatNumber = _this.formatNumber.bind(_this);
            var formatCurrency = _this.formatCurrency.bind(_this);
            // Allows to generate prefixed urls easier
            //
            // @example (both will produce the same result)
            //
            //   l10n.prefix(l10n.t('h24_some_url')) -> '/fr/some-url'
            //   l10n.prefix.t('h24_some_url') -> '/fr/some-url'
            Object.defineProperty(prefix, 't', {
                value: function (key) { return _this.prefix(_this.translate(key)); },
            });
            // Allows to generate translations with replaced placeholders
            Object.defineProperty(replace, 't', {
                value: function (key, replaceMap) {
                    return _this.replace(_this.translate(key), replaceMap);
                },
            });
            _this.state = __assign(__assign({ locales: (_a = {},
                    _a[localeConfig.locale] = props.locales,
                    _a) }, localeConfig), { t: _this.translate.bind(_this), translate: _this.translate.bind(_this), r: replace, replace: replace, l: _this.getLanguages.bind(_this), getLanguages: _this.getLanguages.bind(_this), p: prefix, prefix: prefix, fn: formatNumber, formatNumber: formatNumber, fc: formatCurrency, formatCurrency: formatCurrency });
        }
        return _this;
    }
    LocaleProvider.prototype.splitLocale = function (locale) {
        var _a = __read(locale.split('_'), 3), language = _a[0], region = _a[1], _b = _a[2], theme = _b === void 0 ? BrandTheme.h24 : _b;
        return { locale: locale, language: language, theme: theme, region: region };
    };
    LocaleProvider.prototype.getLanguages = function () {
        var _this = this;
        var _a = this.state, currentLanguage = _a.language, currentRegion = _a.region;
        return LOCALES.filter(function (locale) { return locale.includes(_this.state.region); }).map(function (locale) {
            var _a = __read(locale.split('_'), 2), language = _a[0], region = _a[1];
            var isCurrent = language === currentLanguage && region === currentRegion;
            // INFO: This is base url path prefix dictated by how Shop-Reboot
            // does localization. For example for h24.ch pathPrefix will be
            // "de", for h24.fr -> "fr", for h24.de -> "" as pathPrefix is
            // needed only for multilingual countries
            //
            // Helpful for prefixing urls and API endpoints in some weird
            // cases (hello, Reboot search and cookie policy API 👋 )
            var pathPrefix = MULTILINGUAL_REGIONS.includes(region)
                ? "/".concat(language)
                : '';
            return { language: language, region: region, isCurrent: isCurrent, pathPrefix: pathPrefix };
        });
    };
    LocaleProvider.prototype.prefix = function (url) {
        if (url === void 0) { url = ''; }
        var _a = (this.getLanguages().find(function (lang) { return lang.isCurrent; }) || {}).pathPrefix, pathPrefix = _a === void 0 ? '' : _a;
        return "".concat(pathPrefix).concat(url);
    };
    LocaleProvider.prototype.translate = function (key) {
        var _a;
        var _b = this.state, locale = _b.locale, locales = _b.locales;
        var translation = (_a = locales[locale]) === null || _a === void 0 ? void 0 : _a[key];
        if (!translation) {
            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.warn("No translation for key \"".concat(key, "\" found in translations"));
            }
            return "{{".concat(key, "}}");
        }
        else {
            return translation;
        }
    };
    LocaleProvider.prototype.formatNumber = function (n) {
        var num = Number(n);
        if (isNaN(num)) {
            return "".concat(n);
        }
        // Placeholder for fraction separator in float values.
        var fractionReplacer = '.';
        // Placeholder for number grouping.
        // Matches the carret before every 3 number
        // eg; 1(match)234(match)567
        var groupReplacer = /\B(?=(\d{3})+(?!\d))/;
        var _a = options[this.props.locale] || {}, _b = _a.fractionSeparator, fractionSeparator = _b === void 0 ? '' : _b, _c = _a.groupSeparator, groupSeparator = _c === void 0 ? '' : _c;
        return num
            .toFixed(2)
            .toString()
            .replace(fractionReplacer, fractionSeparator)
            .replace(groupReplacer, groupSeparator);
    };
    LocaleProvider.prototype.formatCurrency = function (amnt) {
        var amount = Number(amnt);
        if (isNaN(amount)) {
            return "".concat(amnt);
        }
        // Prices are always sent as integer.
        // eg; 1,00 € sent as 100 cents
        var amountWithFraction = amount / 100;
        var formattedAmount = this.formatNumber(amountWithFraction);
        var _a = options[this.props.locale] || {}, _b = _a.currencySign, currencySign = _b === void 0 ? '' : _b, _c = _a.currencyPosition, currencyPosition = _c === void 0 ? '' : _c;
        return this.state.r(currencyPosition, {
            amount: formattedAmount,
            currency: currencySign,
        });
    };
    LocaleProvider.prototype.replace = function (str, obj) {
        return str.replace(/{{(\S+?)}}/g, function (_, p1) { return (obj[p1] ? obj[p1] : _); });
    };
    LocaleProvider.prototype.componentDidUpdate = function (prevProps) {
        var _a;
        var _b = this.props, locale = _b.locale, locales = _b.locales;
        if (prevProps.locale !== locale) {
            this.setState(this.splitLocale(locale));
        }
        if (prevProps.locales !== locales) {
            this.setState({ locales: __assign(__assign({}, locales), (_a = {}, _a[locale] = locales, _a)) });
        }
    };
    LocaleProvider.prototype.render = function () {
        return (React.createElement(LocaleContext.Provider, { value: this.state }, this.props.children));
    };
    LocaleProvider.defaultProps = {
        locales: {},
        locale: Locale.De_De_H24,
    };
    return LocaleProvider;
}(React.Component));
export { LocaleProvider };
export var LocaleConsumer = function (_a) {
    var children = _a.children;
    return (React.createElement(LocaleContext.Consumer, null, function (l10n) {
        if (l10n === null) {
            throw new TypeError('It seems that you are trying to use LocaleConsumer out of the LocaleProvider scope. ' +
                'Please, check your render tree');
        }
        return children(l10n);
    }));
};
export function withLocale(Component) {
    var WithLocale = function (props) { return (React.createElement(LocaleConsumer, null, function (l10n) {
        return React.createElement(Component, __assign({ l10n: l10n }, props));
    })); };
    WithLocale.displayName = "withLocale(".concat(name(Component), ")");
    return hoistNonReactStatics(WithLocale, Component);
}
export var useLocalization = function () { return useContext(LocaleContext); };
