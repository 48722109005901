import error from './error';
import marketing from './marketing';
import neutral from './neutral';
import primary from './primary';
import ref from './reference';
import secondary from './secondary';
import success from './success';
import warning from './warning';
export default {
    sys: {
        neutral: neutral,
        primary: primary,
        secondary: secondary,
        warning: warning,
        error: error,
        success: success,
        marketing: marketing,
    },
    ref: ref,
};
