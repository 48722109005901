export default {
    text: {
        default: '#158558',
        hover: '#158558',
        active: '#158558',
        strong: '#05382a',
    },
    background: {
        default: '#179c64',
        weak: '#dcf5df',
        hover: '#158558',
        active: '#146b4a',
    },
    border: {
        default: '#179c64',
        hover: '#179c64',
        active: '#179c64',
    },
    icon: {
        default: '#179c64',
        hover: '#179c64',
        active: '#179c64',
        strong: '#146b4a',
    },
};
