export var Locale;
(function (Locale) {
    Locale["De_De_H24"] = "de_DE_h24";
    Locale["De_De_Ffh"] = "de_DE_ffh";
    Locale["De_At"] = "de_AT";
    Locale["De_Ch"] = "de_CH";
    Locale["Fr_Be"] = "fr_BE";
    Locale["Fr_Ch"] = "fr_CH";
    Locale["Fr_Fr"] = "fr_FR";
    Locale["It_It"] = "it_IT";
    Locale["Nl_Be"] = "nl_BE";
    Locale["Nl_Nl"] = "nl_NL";
})(Locale || (Locale = {}));
/** @deprecated Use `GQLLocale` from `graphql-ts-types` */
export var GQLLocale;
(function (GQLLocale) {
    GQLLocale["DE_DE"] = "de_DE";
    GQLLocale["FR_FR"] = "fr_FR";
    GQLLocale["NL_NL"] = "nl_NL";
    GQLLocale["DE_AT"] = "de_AT";
    GQLLocale["DE_CH"] = "de_CH";
    GQLLocale["FR_CH"] = "fr_CH";
    GQLLocale["NL_BE"] = "nl_BE";
    GQLLocale["FR_BE"] = "fr_BE";
    GQLLocale["IT_IT"] = "it_IT";
})(GQLLocale || (GQLLocale = {}));
