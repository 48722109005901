export default {
    text: {
        default: '#a06a0a',
        hover: '#a06a0a',
        active: '#a06a0a',
        strong: '#4f2600',
    },
    background: {
        default: '#b77e0c',
        weak: '#faefc0',
        hover: '#a06a0a',
        active: '#855207',
    },
    border: {
        default: '#b77e0c',
        hover: '#b77e0c',
        active: '#b77e0c',
    },
    icon: {
        default: '#b77e0c',
        hover: '#b77e0c',
        active: '#b77e0c',
        strong: '#855207',
    },
};
